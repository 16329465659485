import Header from '@/components/Header.vue';
export default {
  name: 'Home',
  components: {
    Header
  },

  data() {
    return {
      fileList: [],
      visible: false,
      percent: 0,
      timer: null,
      resList: [],
      res: 0,
      success: false,
      showImg: false
    };
  },

  mounted() {
    for (let i = 0; i < 44; i++) {
      this.resList.push(`https://fgbmy22.wearetogether.com.cn/public/face-${i + 1}.jpg`);
    }
  },

  methods: {
    handleClick() {
      const input = this.$refs.upload;
      input.click();
    },

    handleChange() {
      const input = this.$refs.upload;
      const curFiles = input.files;

      if (curFiles.length !== 0) {
        const file = URL.createObjectURL(curFiles[0]);
        this.fileList.push(file);
      }
    },

    showLoading() {
      this.visible = true;
      this.timer = setInterval(() => {
        if (this.percent < 100) {
          let num = Math.ceil(Math.random() * 6);

          if (this.percent + num > 100) {
            num = 100 - this.percent;
          }

          this.percent += num;
        } else {
          this.reset();
        }
      }, 200);
    },

    reset() {
      this.visible = false;
      clearInterval(this.timer);
      this.timer = null;
    },

    handleClose() {
      this.percent = 0;
      this.res = Math.floor(Math.random() * this.resList.length);
      this.fileList = [this.resList[this.res]];
      this.success = true;
      this.showImg = true;
    },

    playAgain() {
      this.fileList = [];
      this.success = false;
      this.visible = false;
      this.showImg = false;
      this.res = 0;
      this.timer = null;
    },

    save() {
      // 下载图片
      const xhr = new XMLHttpRequest();
      xhr.open('get', this.resList[this.res]);
      xhr.responseType = 'blob';
      xhr.send();

      xhr.onload = function () {
        if (this.status === 200 || this.status === 304) {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(this.response);

          fileReader.onload = function () {
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = this.result;
            a.download = 'face';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          };
        }
      };
    }

  }
};